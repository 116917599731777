<template>
  <div class="signup-authorization">
    <div class="sw-block-subtitle text-center">
      {{ $vuetify.lang.t("$vuetify.verificationHeadline") }}
      {{
        signupEmailDomain ? user.email + "@" + signupEmailDomain : user.email
      }}
    </div>
    <v-card flat max-width="428" class="sw-mx-auto">
      <ValidationObserver ref="loginObserver" tag="form">
        <ValidationProvider
          vid="pin"
          :name="$vuetify.lang.t('$vuetify.codeFromEmail')"
          mode="passive"
          rules="required|digits:4"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="pin"
            :error-messages="errors[0]"
            :placeholder="$vuetify.lang.t('$vuetify.codeFromEmail')"
            flat
            background-color="#f2f2f2"
            class="rounded-lg registration-field-rounded"
            solo
          ></v-text-field>
        </ValidationProvider>
      </ValidationObserver>
    </v-card>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="login"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.continue") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  data: () => ({
    isLoading: false,
    pin: "",
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store?.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    isUserSubscribed() {
      return this.user?.groups?.includes(this.groupId);
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store?.commit("signup/SET_WINDOW", val);
      },
    },
    signupEmailDomain: {
      get() {
        return this.$store?.getters["signup/plugin"]["attributes"][
          "signup_email_domain"
        ];
      },
    },
    getEmail() {
      return this.signupEmailDomain
        ? this.user.email.split("@")[0] + "@" + this.signupEmailDomain
        : this.user.email;
    },
    signupRequiredPrivacyDisclaimer: {
      get() {
        return this.$store?.getters["signup/plugin"]["attributes"][
          "signup_required_privacy_disclaimer"
        ];
      },
    },
  },
  methods: {
    async getSignupProgress() {
      const params = {
        group_plugin_id: this.plugin?.id,
        per_page: 1,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        { params },
      );

      const category = response?.data?.data?.[0];

      const params2 = {
        only_empty: 1,
        category_id: category.id,
      };

      const response2 = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/next`,
        { params: params2 },
      );

      const isCompleted =
        response2?.data?.meta?.[`reserved_g${category.id}_completed`];

      if (!isCompleted) {
        this.window = 1;
      } else {
        this.window = 6;
      }
    },
    async subscribe() {
      await this.$http.get(`/auth/subscribe`, {
        params: { groups: this.groupId },
      });

      this.user?.groups?.push(this.groupId);
    },
    async login() {
      try {
        const isValid = await this.$refs.loginObserver.validate();

        if (!isValid) return;

        this.isLoading = true;

        const params = {
          email: this.getEmail,
          pin: this.pin,
        };

        const response = await this.$http.get("/auth/login", { params });

        if (!response?.data?.data?.is_latest_terms_accepted) {
          if (
            !this.signupRequiredPrivacyDisclaimer ||
            this.user.is_latest_terms_accepted
          ) {
            await axios({
              url: "/auth/quick_update",
              method: "post",
              baseURL: process.env.VUE_APP_API_URL,
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${response?.data?.data?.token}`,
              },
              params: {
                terms_accepted_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
              },
            });
          } else {
            this.isLoading = false;
            return;
          }
        }

        const user = response?.data?.data || { lang_pref: "" };
        const token = response?.data?.data?.token || "";

        this.$store.commit("SET_USER", user);
        this.$store?.commit("SET_USER_TOKEN", token);

        if (!this.isUserSubscribed) {
          await this.subscribe();
        }

        await this.getSignupProgress();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;

          this.$refs.loginObserver.setErrors({
            pin: [this.$vuetify.lang.t("$vuetify.invalidVerificationCode")],
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.registration-field-rounded.v-text-field.v-text-field--solo
  > .v-input__control {
  border-radius: 20px;
}
.registration-field-rounded .v-input__slot {
  padding-left: 20px !important;
}
</style>
