<template>
  <div class="signup-payment">
    <div class="sw-block-subtitle text-center">
      {{ $vuetify.lang.t("$vuetify.addPaymentInformation") }}
    </div>
    <v-row no-gutters>
      <v-col cols="6" class="sw-pr-7">
        <div class="grey--text">{{ $vuetify.lang.t("$vuetify.summary") }}</div>
        <div class="font-weight-thin">
          {{ $vuetify.lang.t("$vuetify.yourOrder") }}
        </div>
        <v-row
          v-for="(attribute, i) in transformedUserAttributes"
          :key="`answer-${i}`"
          no-gutters
        >
          <v-col cols="12" class="font-weight-regular">{{
            attribute.name
          }}</v-col>
          <v-col v-if="attribute.type === 'options'" cols="12">
            <v-row
              v-for="(answer, i) in attribute.answer"
              :key="`answer-${i}`"
              no-gutters
            >
              <v-col cols="9" class="font-weight-light">
                {{ answer.name }}
              </v-col>
              <v-col cols="3" class="text-right">
                {{ answer.price / 100 }} {{ groupCurrency }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="sw-my-3"></v-divider>
        <div>{{ $vuetify.lang.t("$vuetify.payment") }}</div>
        <v-row no-gutters>
          <v-col cols="6">{{
            $vuetify.lang.t("$vuetify.totalExcludingVat")
          }}</v-col>
          <v-col cols="6" class="text-right"
            >{{ (cart.total - cart.tax) / 100 }} {{ groupCurrency }}</v-col
          >
        </v-row>
        <v-row no-gutters>
          <v-col cols="6"
            >{{ $vuetify.lang.t("$vuetify.vat") }} {{ defaultTaxRate }}%</v-col
          >
          <v-col cols="6" class="text-right"
            >{{ cart.tax / 100 }} {{ groupCurrency }}</v-col
          >
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">{{
            $vuetify.lang.t("$vuetify.totalIncludingVat")
          }}</v-col>
          <v-col cols="6" class="text-right"
            >{{ cart.total / 100 }} {{ groupCurrency }}</v-col
          >
        </v-row>
      </v-col>
      <v-col cols="6" class="sw-pl-7 payment-details">
        <v-row no-gutters>
          <v-col cols="12" class="mb-2">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.paymentMethod") }}
            </div>
            <v-select
              :placeholder="$vuetify.lang.t('$vuetify.creditCard')"
              disabled
              solo
              hide-details
              class="sw-field-background sw-rounded sw-height-48px"
            ></v-select>
            <v-row no-gutters class="align-center pt-1">
              <span class="pr-1 pl-4"
                ><img :src="require(`@/assets/images/visa.png`)"
              /></span>
              <span class="pr-1"
                ><img :src="require(`@/assets/images/mastercard.png`)"
              /></span>
            </v-row>
          </v-col>
          <v-col cols="12" class="mb-5">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.creditCardNumber") }}*
            </div>
            <card-number
              ref="cardNumber"
              :stripe="stripeKey"
              :options="{ placeholder: '', ...cardElementStyle }"
              class="
                stripe-element
                card-number
                sw-field-background sw-rounded sw-height-48px
              "
              @change="number = $event.complete"
            />
          </v-col>
          <v-col cols="6" class="mb-5 pr-1">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.cardExpiry") }}*
            </div>
            <card-expiry
              ref="cardExpiry"
              :stripe="stripeKey"
              :options="{
                placeholder: '',
                ...cardElementStyle,
              }"
              class="
                stripe-element
                card-expiry
                sw-field-background sw-rounded sw-height-48px
              "
              @change="expiry = $event.complete"
            />
          </v-col>
          <v-col cols="6" class="mb-5 pl-1">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.cardCvc") }}*
            </div>
            <card-cvc
              ref="cardCvc"
              :stripe="stripeKey"
              :options="{ placeholder: '', ...cardElementStyle }"
              class="
                stripe-element
                card-cvc
                sw-field-background sw-rounded sw-height-48px
              "
              @change="cvc = $event.complete"
            />
          </v-col>
          <v-col cols="6" class="mb-5 pr-1">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.name") }}*
            </div>
            <v-text-field
              v-model="address.first_name"
              solo
              hide-details
              class="sw-field-background sw-rounded sw-height-48px"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="mb-5 pl-1">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.surname") }}*
            </div>
            <v-text-field
              v-model="address.last_name"
              solo
              hide-details
              class="sw-field-background sw-rounded sw-height-48px"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-5">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.street") }}
            </div>
            <v-text-field
              v-model="address.street"
              solo
              hide-details
              class="sw-field-background sw-rounded sw-height-48px"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="mb-5 pr-1">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.state") }}
            </div>
            <v-text-field
              v-model="address.state"
              solo
              hide-details
              class="sw-field-background sw-rounded sw-height-48px"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="mb-5 pl-1">
            <div class="mb-1 text-left caption">
              {{ $vuetify.lang.t("$vuetify.postalCode") }}
            </div>
            <v-text-field
              v-model="address.postal_code"
              solo
              hide-details
              class="sw-field-background sw-rounded sw-height-48px"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="cardPaymentErrorMessage" class="sw-small red--text text-center">
      {{ cardPaymentErrorMessage }}
    </div>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        class="
          sw-mx-1
          text-none
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="window = 4"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.back") }}
        </span>
      </v-btn>
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          sw-mx-1
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        :disabled="!isCardDetailsValid"
        @click="savePaymentDetailsAndPay"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.continueAndPay") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  CardNumber,
  CardExpiry,
  CardCvc,
  handleCardPayment,
} from "vue-stripe-elements-plus";
import qs from "qs";

export default {
  data: () => ({
    isLoading: false,
    cardPaymentErrorMessage: "",
    address: {},
    category: {},
    transformedUserAttributes: [],
    reservedUserAttributes: [],
    cart: {},
    isBought: false,
    number: false,
    expiry: false,
    cvc: false,
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    defaultTaxRate() {
      return this.$store?.getters?.group?.default_tax_rate;
    },
    stripeKey() {
      return process.env.VUE_APP_STRIPE_KEY;
    },
    reservedPaymentTotal() {
      return this.reservedUserAttributes?.find(
        (el) => el.slug === `reserved_g${this.category?.id}_payment_total`,
      );
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store?.commit("signup/SET_WINDOW", val);
      },
    },
    cardElementStyle() {
      return {
        style: {
          base: {
            fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
            lineHeight: "48px",
            fontSize: "16px",
            fontWeight: "300",
            "::placeholder": {
              fontSize: "12px",
              color: "#b6b6b6",
            },
          },
        },
      };
    },
    isCardDetailsValid() {
      if (!this.address.first_name || !this.address.last_name) {
        return false;
      }

      return (
        (this.number && this.expiry && this.cvc) ||
        (this.paymentMethod && this.paymentMethod.id)
      );
    },
  },
  components: { CardNumber, CardExpiry, CardCvc },
  watch: {
    window: {
      immediate: true,
      handler(val) {
        if (val === 5) this.fetchAll();
      },
    },
    appLanguage: {
      handler() {
        this.fetchAll();
      },
    },
  },
  methods: {
    async getAddress() {
      const response = await this.$http.get(`/auth/addresses`);

      if (response?.data?.data[0]) {
        this.address = response.data.data[0];
      } else {
        this.address = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
        };
      }
    },
    async getSignupCategory() {
      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        {
          params: {
            group_plugin_id: this.plugin?.id,
            per_page: 1,
          },
        },
      );

      if (!response?.data?.data?.[0]) return;

      this.category = response?.data?.data?.[0];
    },
    async listUserAttributes() {
      this.transformedUserAttributes = [];

      const params = {
        category_id: this.category.id,
        lang: this.appLanguage,
        per_page: 10000,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/list_related`,
        { params },
      );

      if (!response?.data?.data) return;

      for (const attribute of response?.data?.data) {
        if (attribute?.type !== "options") continue;

        const value = response?.data?.meta[attribute?.slug];

        if (attribute?.is_collection) {
          attribute.answer = attribute.options
            .filter((option) => value.includes(option.id))
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        } else {
          attribute.answer = attribute.options
            .filter((option) => value === option.id)
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        }

        attribute.answer = attribute.answer.filter((el) => el.price);

        if (!attribute.answer.length) continue;

        this.transformedUserAttributes.push(attribute);
      }
    },
    async listReservedAttributes() {
      const params = {
        categories: this.category?.id,
        reserved_only: 1,
        per_page: 500,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes`,
        { params },
      );

      this.reservedUserAttributes = response?.data?.data;
    },
    async getCart() {
      const params = qs.stringify({
        products: [
          {
            name: `Attribute:${this.reservedPaymentTotal?.id}`,
            amount: 1,
          },
        ],
        currency: this.groupCurrency,
      });

      const response = await this.$http.get(`/auth/cart?${params}`);

      this.cart = response.data.data;
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getAddress();
        await this.getSignupCategory();
        await this.listUserAttributes();
        await this.listReservedAttributes();
        await this.getCart();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    transformAddressForSave(address) {
      if (!address) return {};

      return {
        first_name: address.first_name,
        last_name: address.last_name,
        street: address.street,
        state: address.state,
        postal_code: address.postal_code,
      };
    },
    async createAddress() {
      const specs = {
        label: "Default address",
        ...this.transformAddressForSave(this.address),
      };

      await this.$http.post(`/auth/addresses`, specs);
    },
    async updateAddress() {
      const specs = this.transformAddressForSave(this.address);

      await this.$http.post(`/auth/addresses/${this.address.id}`, specs);
    },
    async hasPaid() {
      const response = await this.$http.get(`/auth/is_bought`, {
        params: { product: `Attribute:${this.reservedPaymentTotal.id}` },
      });

      this.isBought = response.data.data.is_bought;
    },
    async savePaymentDetailsAndPay() {
      try {
        this.isLoading = true;

        if (!this.address.id) {
          await this.createAddress();
        } else {
          await this.updateAddress();
        }

        // Stripe intent
        const paymentIntentResponse = await this.$http.post(
          `/auth/stripe/payment_intention`,
          {
            amount: this.cart.total,
            currency: this.groupCurrency,
            reason: `purchase:Attribute,${this.reservedPaymentTotal.id}`,
          },
        );

        if (
          !paymentIntentResponse?.data?.data?.payment_intention ||
          paymentIntentResponse?.error
        ) {
          if (paymentIntentResponse.error) {
            this.showNotification(paymentIntentResponse.error.message, "error");
          }
          return;
        }

        const clientSecret = paymentIntentResponse.data.data.payment_intention;

        // Confirm payment method
        const handleCardPaymentResponse = await handleCardPayment(
          clientSecret,
          {
            payment_method_data: {
              billing_details: {
                address: {
                  line1: this.address.street,
                  postal_code: this.address.postal_code,
                  state: this.address.state,
                },
                name: `${this.address.first_name} ${this.address.last_name}`,
              },
            },
          },
        );

        if (handleCardPaymentResponse.error) {
          this.isLoading = false;

          this.cardPaymentErrorMessage =
            handleCardPaymentResponse.error.message;

          return;
        } else {
          this.cardPaymentErrorMessage = "";
        }

        await this.$http.get(
          `/auth/stripe/payment_intention/${handleCardPaymentResponse?.paymentIntent?.id}`,
        );

        await this.hasPaid();

        if (!this.isBought) {
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.window = 6;
      } catch (error) {
        if (error) {
          this.isLoading = false;

          this.showNotification(
            this.$vuetify.lang.t("$vuetify.paymentError"),
            "error",
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.payment-details {
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 48px !important;
  }

  .v-input__slot {
    background-color: transparent !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    height: 48px;
    padding-left: 16px !important;
  }

  .card-number,
  .card-expiry,
  .card-cvc {
    padding: 0 16px;
    height: 48px;
  }

  .caption {
    padding-left: 16px;
  }
}
</style>
