<template>
  <div class="signup-overview">
    <div
      :style="{ color: gmPrimaryColor }"
      class="sw-block-subtitle text-center"
    >
      {{ $vuetify.lang.t("$vuetify.confirmationHeadline") }}
    </div>
    <div class="sw-mb-5 sw-text text-center">
      {{ $vuetify.lang.t("$vuetify.editInformationText") }}
    </div>
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- profile -->
    <v-row no-gutters class="sw-text">
      <v-col cols="10">
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.name") }}
          </v-col>
          <v-col cols="5">
            {{ user.first_name }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.surname") }}
          </v-col>
          <v-col cols="5">
            {{ user.last_name }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.phone") }}
          </v-col>
          <v-col v-if="user.phone_code && user.phone" cols="5"
            >+{{ user.phone_code }} {{ user.phone }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.email") }}
          </v-col>
          <v-col cols="5">
            {{ user.email }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="text-right">
        <a :style="{ color: gmSecondaryColor }" @click.stop="editUser">
          {{ $vuetify.lang.t("$vuetify.edit") }}
        </a>
      </v-col>
    </v-row>
    <!-- eo profile -->
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- answers -->
    <div class="pb-5 mb-4 sw-text">
      {{ $vuetify.lang.t("$vuetify.yourAnswers") }}
    </div>
    <template v-if="!attributeTagsEnabled">
      <template v-for="(attribute, i) in transformedUserAttributes">
        <v-row
          v-if="!attribute.hidden"
          :key="`answer-${i}`"
          no-gutters
          class="sw-text mb-4"
        >
          <v-col cols="9">
            <h5>{{ attribute.name }}</h5>
          </v-col>
          <v-col cols="3" class="text-right">
            <a
              :style="{ color: gmSecondaryColor }"
              @click.stop="changeAnswer(attribute)"
              >{{ $vuetify.lang.t("$vuetify.edit") }}</a
            >
          </v-col>
          <v-col v-if="attribute.type === 'options'" cols="12">
            <p>
              <template v-for="(answer, index) in attribute.answer">
                <div :key="`answer-${index}`">
                  {{ answer.name }}
                  <span v-if="answer.price"
                    >({{ answer.price / 100 }} {{ groupCurrency }})</span
                  >
                </div>
              </template>
            </p>
          </v-col>
          <v-col
            v-if="attribute.type === 'varchar'"
            cols="12"
            class="font-weight-light"
          >
            {{ attribute.answer }}
          </v-col>
          <v-col
            v-if="attribute.type === 'text'"
            cols="12"
            class="font-weight-light"
          >
            <nl2br tag="p" :text="attribute.answer" />
          </v-col>
          <v-col
            v-if="attribute.type === 'datetime'"
            cols="12"
            class="font-weight-light"
          >
            {{ dateFormattedDate(attribute.answer) }}
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-if="attributeTagsEnabled">
      <v-row
        v-for="(tag, tagIndex) in groupedQuestions"
        :key="`tag-${tagIndex}`"
        class="no-gutters no-wrap pb-5"
      >
        <v-col cols="10">
          <div v-if="!tag.hide_name || !tag.hide_description" class="mb-4">
            <template v-if="!tag.hide_name">
              <h4>
                <strong>{{ tag.name }}</strong>
              </h4>
            </template>
            <template v-if="!tag.hide_description">
              <p>{{ tag.description }}</p>
            </template>
          </div>
          <template v-for="(attribute, i) in tag.attributes">
            <div
              v-if="!attribute.hidden"
              :key="`answer-${i}`"
              class="sw-text mb-4"
            >
              <h5>{{ attribute.name }}</h5>

              <template v-if="attribute.type === 'options'">
                <p>
                  <template v-for="(answer, index) in attribute.answer">
                    <div :key="`answer-${index}`">
                      {{ answer.name }}
                      <span v-if="answer.price"
                        >({{ answer.price / 100 }} {{ groupCurrency }})</span
                      >
                    </div>
                  </template>
                </p>
              </template>

              <template v-if="attribute.type === 'text'">
                <nl2br tag="p" :text="attribute.answer" />
              </template>

              <template v-if="attribute.type === 'varchar'">
                <p>{{ attribute.answer }}</p>
              </template>

              <div
                v-if="attribute.type === 'datetime'"
                class="font-weight-light"
              >
                <p>{{ dateFormattedDate(attribute.answer) }}</p>
              </div>
            </div>
          </template>
        </v-col>
        <v-col cols="2" class="align-self-center">
          <div class="text-right">
            <a
              :style="{ color: gmSecondaryColor }"
              @click.stop="changeAnswers(tag)"
              >{{ $vuetify.lang.t("$vuetify.edit") }}</a
            >
          </div>
        </v-col>
      </v-row>
    </template>
    <!-- eo answers -->
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- payment -->
    <template v-if="cart.total">
      <div class="font-weight-thin">
        {{ $vuetify.lang.t("$vuetify.payment") }}
      </div>
      <v-row no-gutters class="font-italic">
        <v-col cols="9"
          >{{ $vuetify.lang.t("$vuetify.totalExcludingVat") }} ({{
            defaultTaxRate
          }}%)</v-col
        >
        <v-col cols="3"
          >{{ (cart.total - cart.tax) / 100 }} {{ groupCurrency }}</v-col
        >
      </v-row>
      <v-row no-gutters>
        <v-col cols="9"
          >{{ $vuetify.lang.t("$vuetify.totalIncludingVat") }} ({{
            defaultTaxRate
          }}%)</v-col
        >
        <v-col cols="3" class="font-weight-regular"
          >{{ cart.total / 100 }} {{ groupCurrency }}</v-col
        >
      </v-row>
      <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    </template>
    <!-- eo payment -->
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="next"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.finishRegistration") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";

export default {
  data: () => ({
    isLoading: false,
    category: {},
    transformedUserAttributes: [],
    reservedUserAttributes: [],
    cart: {},
    isBought: false,
    dateFormat: null,
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    defaultTaxRate() {
      return this.$store?.getters?.group?.default_tax_rate;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    reservedPaymentTotal() {
      return this.reservedUserAttributes?.find(
        (el) => el.slug === `reserved_g${this.category?.id}_payment_total`,
      );
    },
    attributeTags() {
      return this.$store?.getters["signup/attributeTags"];
    },
    attributeTagsEnabled() {
      return this.plugin.attributes?.signup_guests_attribute_tags;
    },
    groupedQuestions() {
      return this.attributeTags.map((tag) => ({
        ...tag,
        attributes: this.transformedUserAttributes.filter((attr) =>
          attr.tags.some((el) => el.id === tag.id),
        ),
      }));
    },
    selectedQuestion: {
      get() {
        return this.$store?.getters["signup/selectedQuestion"];
      },
      set(val) {
        this.$store.commit("signup/SET_SELECTED_QUESTION", val);
      },
    },
    selectedQuestionGroup: {
      get() {
        return this.$store?.getters["signup/selectedQuestionGroup"];
      },
      set(val) {
        this.$store.commit("signup/SET_SELECTED_QUESTION_GROUP", val);
      },
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store?.commit("signup/SET_WINDOW", val);
      },
    },
    dateFormattedDate() {
      return (date) => moment(date).format(this.dateFormat);
    },
  },
  watch: {
    window: {
      immediate: true,
      handler(val) {
        if (val !== 4) return;

        this.fetchAll();
      },
    },
    appLanguage: {
      handler() {
        this.refetchUserAttributes();
      },
    },
  },
  methods: {
    editUser() {
      this.window = 1;
    },
    async getSignupCategory() {
      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        {
          params: {
            group_plugin_id: this.plugin?.id,
            per_page: 1,
          },
        },
      );

      if (!response?.data?.data?.[0]) return;

      this.category = response?.data?.data?.[0];
    },
    async listUserAttributes() {
      this.transformedUserAttributes = [];

      const params = {
        category_id: this.category.id,
        lang: this.appLanguage,
        per_page: 10000,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/list_related`,
        { params },
      );

      if (!response?.data?.data) return;

      for (const attribute of response?.data?.data) {
        const value = response?.data?.meta[attribute?.slug];

        attribute.meta_value = value;

        if (["varchar", "datetime", "text"].includes(attribute.type)) {
          attribute.answer = value || "";
        }

        if (attribute.type === "options" && attribute?.is_collection) {
          attribute.answer = attribute.options
            .filter((option) => value.includes(option.id))
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        }

        if (attribute.type === "options" && !attribute?.is_collection) {
          attribute.answer = attribute.options
            .filter((option) => value === option.id)
            .map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
            }));
        }

        this.transformedUserAttributes.push(attribute);
      }
    },
    async refetchUserAttributes() {
      try {
        this.isLoading = true;
        await this.listUserAttributes();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async listReservedAttributes() {
      const params = {
        categories: this.category?.id,
        reserved_only: 1,
        per_page: 500,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes`,
        { params },
      );

      this.reservedUserAttributes = response?.data?.data;
    },
    async getCart() {
      const params = qs.stringify({
        products: [
          {
            name: `Attribute:${this.reservedPaymentTotal?.id}`,
            amount: 1,
          },
        ],
        currency: this.groupCurrency,
      });

      const response = await this.$http.get(`/auth/cart?${params}`);

      this.cart = response.data.data;
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getSignupCategory();
        await this.listUserAttributes();
        await this.listReservedAttributes();
        await this.getCart();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    transformAttributeForEdit(attribute) {
      return {
        ...attribute,
        details: attribute.details ? JSON.parse(attribute.details) : {},
      };
    },
    changeAnswer(attribute) {
      this.selectedQuestion = this.transformAttributeForEdit(attribute);
      this.user.attributes[attribute.slug] = attribute.meta_value;
      this.window = 3;
    },
    changeAnswers(tag) {
      this.selectedQuestionGroup = tag;
      this.window = 3;
    },
    async hasPaid() {
      const response = await this.$http.get(`/auth/is_bought`, {
        params: { product: `Attribute:${this.reservedPaymentTotal.id}` },
      });

      this.isBought = response.data.data.is_bought;
    },
    async pay() {
      await this.$http.post(`/auth/buy`, {
        product: `Attribute:${this.reservedPaymentTotal.id}`,
      });
    },
    async next() {
      if (this.cart?.total > 0) {
        this.window = 5;
        return;
      }

      try {
        this.isLoading = true;

        await this.pay();
        await this.hasPaid();

        if (!this.isBought) {
          this.isLoading = false;
          return;
        }

        this.$store.commit("SET_SIGNUP_QUERY", null);

        this.isLoading = false;

        this.window = 6;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    this.$http.get(`/settings/public/global.date_format`).then((res) => {
      this.dateFormat = res.data.data.value;
    });
  },
};
</script>

<style lang="scss" scoped></style>
