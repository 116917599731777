<template>
  <div class="signup-questions-grouped">
    <ValidationObserver ref="questionValidationObserver">
      <div v-if="questionGroup && questionGroup.id">
        <div
          class="sw-block-subtitle text-center"
          v-if="!questionGroup.hide_name"
        >
          {{ questionGroup.name }}
        </div>
        <p v-if="!questionGroup.hide_description" class="text-center">
          {{ questionGroup.description }}
        </p>
        <template v-for="(attribute, attrIndex) in questionGroup.attributes">
          <div v-if="!attribute.hidden" :key="`attribute-${attrIndex}`">
            <div class="sw-block-subtitle text-center">
              {{ attribute.name }}
            </div>
            <template v-if="attribute.details && attribute.details.description">
              <div
                v-html="attribute.details.description"
                class="pb-2 clearfix text-center"
              ></div>
            </template>
            <template v-if="attribute.type === 'options'">
              <template
                v-if="
                  attribute.details &&
                    attribute.details.display_type === 'customized'
                "
              >
                <v-card flat max-width="680" class="sw-mx-auto">
                  <ValidationProvider
                    rules="required"
                    mode="passive"
                    :name="attribute.name"
                    v-slot="{ errors }"
                  >
                    <CustomizedOptionSelect
                      v-model="userAnswers[attribute.slug]"
                      :items="attribute.options"
                      :multi="attribute.is_collection"
                      :errorMessages="errors[0]"
                      @change="listRelatedAttributes(userAnswers)"
                    />
                  </ValidationProvider>
                </v-card>
              </template>
              <template v-else>
                <v-card
                  v-if="attribute.is_collection"
                  flat
                  max-width="356"
                  class="sw-mx-auto custom-select"
                >
                  <ValidationProvider
                    rules="required"
                    mode="passive"
                    :name="attribute.name"
                    v-slot="{ errors }"
                  >
                    <MultiSelect
                      v-model="userAnswers[attribute.slug]"
                      :items="attribute.options"
                      :errorMessages="errors[0]"
                      @input="listRelatedAttributes(userAnswers)"
                    />
                  </ValidationProvider>
                </v-card>
                <v-card
                  v-if="!attribute.is_collection"
                  flat
                  max-width="356"
                  class="sw-mx-auto custom-select"
                >
                  <ValidationProvider
                    rules="required"
                    mode="passive"
                    :name="attribute.name"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      v-model="userAnswers[attribute.slug]"
                      :errorMessages="errors[0]"
                      :items="filterAvailableOptions(attribute)"
                      :menu-props="{ closeOnContentClick: true }"
                      item-text="name"
                      item-value="id"
                      :placeholder="$vuetify.lang.t('$vuetify.chooseAnswer')"
                      flat
                      background-color="#f2f2f2"
                      class="custom-select rounded-lg registration-field-rounded"
                      solo
                      append-icon="mdi-magnify"
                      @change="listRelatedAttributes(userAnswers)"
                    >
                      <template v-slot:selection="{ item }">
                        <span class="mr-2 text-overflow--ellipsis">
                          {{ item.name }}
                        </span>
                        <span
                          v-if="item.price"
                          class="mr-2 text-overflow--ellipsis"
                        >
                          ({{ item.price / 100 }} {{ groupCurrency }})
                        </span>
                      </template>
                      <template slot="item" slot-scope="data">
                        <div class="text-overflow--wrap py-3">
                          <span>{{ data.item.name }}</span>
                          <span v-if="data.item.price" class="ml-2">
                            ({{ data.item.price / 100 }} {{ groupCurrency }})
                          </span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-card>
              </template>
            </template>
            <v-card
              v-if="attribute.type === 'varchar'"
              flat
              max-width="356"
              class="sw-mx-auto"
            >
              <ValidationProvider
                rules="required"
                mode="passive"
                :name="attribute.name"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="userAnswers[attribute.slug]"
                  :errorMessages="errors[0]"
                  :placeholder="$vuetify.lang.t('$vuetify.writeAnswer')"
                  flat
                  background-color="#f2f2f2"
                  class="rounded-lg registration-field-rounded"
                  solo
                ></v-text-field>
              </ValidationProvider>
            </v-card>
            <v-card
              v-if="attribute.type === 'text'"
              flat
              max-width="356"
              class="sw-mx-auto"
            >
              <ValidationProvider
                rules="required"
                mode="passive"
                :name="attribute.name"
                v-slot="{ errors }"
              >
                <v-textarea
                  v-model="userAnswers[attribute.slug]"
                  :errorMessages="errors[0]"
                  :placeholder="$vuetify.lang.t('$vuetify.writeAnswer')"
                  no-resize
                  flat
                  background-color="#f2f2f2"
                  class="rounded-lg registration-field-rounded"
                  solo
                ></v-textarea>
              </ValidationProvider>
            </v-card>
            <v-card
              v-if="attribute.type === 'datetime'"
              flat
              max-width="356"
              class="sw-mx-auto"
            >
              <ValidationProvider
                rules="required"
                mode="passive"
                :name="attribute.name"
                v-slot="{ errors }"
              >
                <date-picker
                  :errorMessages="errors[0]"
                  v-model="userAnswers[attribute.slug]"
                >
                </date-picker>
              </ValidationProvider>
            </v-card>
          </div>
        </template>
      </div>
    </ValidationObserver>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        class="
          sw-mx-2
          text-none
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        :disabled="
          !previousQuestionGroup.id ||
            (!isLoading && isHiddenQuestionMissingAnswer)
        "
        @click="getPreviousQuestionGroup"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.back") }}
        </span>
      </v-btn>
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          sw-mx-2
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        :disabled="!isLoading && isHiddenQuestionMissingAnswer"
        @click="updateAnswers"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.continue") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/CustomFields/MultiSelect.vue";
import CustomizedOptionSelect from "@/components/CustomFields/CustomizedOptionSelect.vue";
import DatePicker from "@/components/CustomFields/DatePicker.vue";
import EventBus from "@/utils/eventBus";

export default {
  data: () => ({
    isLoading: false,
    defaultUserAnswers: {},
    userAnswers: {},
    relatedAttributes: [],
    questionGroup: {},
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store?.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    category() {
      return this.$store?.getters["signup/category"];
    },
    attributeTags() {
      return this.$store?.getters["signup/attributeTags"];
    },
    signupQuery: {
      get() {
        return this.$store?.getters?.signupQuery;
      },
      set(val) {
        this.$store.commit("SET_SIGNUP_QUERY", val);
      },
    },
    groupedQuestions() {
      return this.attributeTags.map((tag) => ({
        ...tag,
        attributes: this.relatedAttributes.filter((attr) =>
          attr.tags.some((el) => el.id === tag.id),
        ),
      }));
    },
    previousQuestionGroup() {
      const currentIndex = this.groupedQuestions.findIndex(
        (el) => el.id === this.questionGroup.id,
      );
      const previousGroup = this.groupedQuestions[currentIndex - 1];

      return previousGroup || {};
    },
    selectedQuestionGroup: {
      get() {
        return this.$store?.getters["signup/selectedQuestionGroup"];
      },
      set(val) {
        this.$store.commit("signup/SET_SELECTED_QUESTION_GROUP", val);
      },
    },
    isHiddenQuestionMissingAnswer() {
      return !!this.questionGroup?.attributes?.find(
        (el) =>
          el.hidden &&
          !this.userAnswers[el.slug] &&
          !this.userAnswers[el.slug]?.length,
      )?.id;
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store.commit("signup/SET_WINDOW", val);
      },
    },
  },
  components: {
    MultiSelect,
    CustomizedOptionSelect,
    DatePicker,
  },
  watch: {
    window: {
      handler(newVal) {
        if (newVal !== 3) return;

        this.getQuestionGroup();
      },
    },
    appLanguage: {
      handler() {
        this.listRelatedAttributes();
      },
    },
  },
  mounted() {
    this.listRelatedAttributes();
  },
  methods: {
    filterAvailableOptions(attribute) {
      if (!attribute || !attribute.options) return [];

      const selectedItem = this.defaultUserAnswers[attribute.slug];

      return attribute.options
        .map((el) => {
          if (el.type === "options" && el.usage_available !== 0) {
            el.options.forEach((opt) => {
              if (selectedItem != null) {
                if (el.is_collection && selectedItem.length) {
                  const contains = selectedItem.find(
                    (v) => v == opt.id || v.id == opt.id,
                  );
                  if (contains) opt.usage_available += 1;
                } else if (selectedItem == opt.id) {
                  opt.usage_available += 1;
                }
              }
            });
          }

          return el;
        })
        .filter((el) => el.usage_available !== 0);
    },
    showSetHiddenAnswerErrorMessage() {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 30000,
        message: this.$vuetify.lang.t("$vuetify.hiddenQuestionErrorMessage"),
      });
    },
    getQuestionGroup() {
      const { id } = this.selectedQuestionGroup;

      if (id) {
        this.questionGroup =
          this.groupedQuestions.find((tag) => tag.id === id) || {};
        return;
      }

      const unansweredGroup = this.groupedQuestions.find((tag) =>
        tag.attributes.some((el) => {
          const userAnswer = this.defaultUserAnswers[el.slug];
          return (
            !userAnswer ||
            (Array.isArray(userAnswer) && userAnswer.length === 0)
          );
        }),
      );

      this.questionGroup = unansweredGroup || {};

      if (this.isHiddenQuestionMissingAnswer) {
        this.showSetHiddenAnswerErrorMessage();
        return;
      }

      const querySlug = this.signupQuery
        ? Object.keys(this.signupQuery)[0]
        : null;

      if (querySlug) {
        const currentGroupIndex = this.groupedQuestions.findIndex(
          (tag) => tag.id === this.questionGroup.id,
        );
        const groupIndexOfAnswer = this.groupedQuestions.findIndex((tag) =>
          tag.attributes.some((attr) => attr.slug === querySlug),
        );
        const groupOfAnswer = this.groupedQuestions.find((tag) =>
          tag.attributes.some((attr) => attr.slug === querySlug),
        );

        if (groupIndexOfAnswer === -1) return;

        if (this.questionGroup.id && groupIndexOfAnswer > currentGroupIndex) {
          return;
        }

        this.selectedQuestionGroup = groupOfAnswer;
        this.questionGroup = groupOfAnswer;
      }
    },
    getPreviousQuestionGroup() {
      this.$refs.questionValidationObserver.reset();

      this.questionGroup = this.previousQuestionGroup;
      this.selectedQuestionGroup = this.questionGroup;

      this.scrollToEl("#registration-anchor", 65);
    },
    transformAttributeForEdit(attribute) {
      return {
        ...attribute,
        details: attribute.details ? JSON.parse(attribute.details) : {},
      };
    },
    async listRelatedAttributesSilent(answers) {
      const params = {
        answers: answers,
        category_id: this.category.id,
        lang: this.appLanguage,
      };

      const response = await this.$http.post(
        `/groups/${this.groupId}/users/attributes/list_related`,
        params,
      );

      this.relatedAttributes = response?.data?.data?.map((el) =>
        this.transformAttributeForEdit(el),
      );
      this.userAnswers = response?.data?.meta;
    },
    async listRelatedAttributes(answers) {
      try {
        this.isLoading = true;

        if (Object.keys(this.userAnswers).length === 0) {
          await this.listRelatedAttributesSilent();
        }

        const querySlug = this.signupQuery
          ? Object.keys(this.signupQuery)[0]
          : null;

        if (querySlug && !answers) {
          let queryValue = this.signupQuery?.[querySlug];

          if (Array.isArray(queryValue)) {
            queryValue = queryValue.map((el) => Number(el));
          } else if (
            queryValue &&
            typeof queryValue === "string" &&
            !isNaN(queryValue)
          ) {
            queryValue = Number(queryValue);
          }

          this.userAnswers[querySlug] = queryValue;
        }

        await this.listRelatedAttributesSilent(this.userAnswers);

        this.isLoading = false;

        if (!answers) {
          this.defaultUserAnswers = JSON.parse(
            JSON.stringify(this.userAnswers),
          );
        }

        this.getQuestionGroup();
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async updateAnswers() {
      try {
        const isValid = await this.$refs.questionValidationObserver.validate();

        if (!isValid) return;

        this.isLoading = true;

        const specs = {};

        for (const el of this.questionGroup.attributes) {
          specs[el.slug] = this.userAnswers[el.slug];
        }

        await this.$http.post(`/auth/quick_update`, specs);

        const querySlug = this.signupQuery
          ? Object.keys(this.signupQuery)[0]
          : null;

        if (querySlug) {
          const queryAttribute = this.questionGroup?.attributes?.find(
            (el) => el.slug === querySlug,
          );

          if (queryAttribute?.id) this.signupQuery = null;
        }

        this.scrollToEl("#registration-anchor", 65);

        this.selectedQuestionGroup = {};

        await this.listRelatedAttributes();

        if (!this.questionGroup?.id) this.window = 4;

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
